<template lang="pug">
  transition(
    :name="name"
    :mode="mode"
    @before-enter="onBeforeEnter"
    @before-leave="onBeforeLeave"
    @before-appear="onBeforeAppear"
    @enter="onEnter"
    @leave="onLeave"
    @appear="onAppear"
    @after-enter="onAfterEnter"
    @after-leave="onAfterLeave"
    @after-appear="onAfterAppear"
    @enter-cancelled="onEnterCancelled"
    @leave-cancelled="onLeaveCancelled"
    @appear-cancelled="onAppearCancelled"
  )
    slot
</template>

<script lang="ts">
  export default defineNuxtComponent({
    name: 'TransitionLayout',
    props: {
      name: {
        type: String,
        default: 'fade',
      },
      mode: {
        type: String,
        default: 'out-in',
      },
      beforeEnter: {
        type: Function,
        default: null,
      },
      beforeLeave: {
        type: Function,
        default: null,
      },
      beforeAppear: {
        type: Function,
        default: null,
      },
      enter: {
        type: Function,
        default: null,
      },
      leave: {
        type: Function,
        default: null,
      },
      appear: {
        type: Function,
        default: null,
      },
      afterEnter: {
        type: Function,
        default: null,
      },
      afterLeave: {
        type: Function,
        default: null,
      },
      afterAppear: {
        type: Function,
        default: null,
      },
      enterCancelled: {
        type: Function,
        default: null,
      },
      leaveCancelled: {
        type: Function,
        default: null,
      },
      appearCancelled: {
        type: Function,
        default: null,
      },
    },
    methods: {
      onBeforeEnter(): void {
        if (typeof this.beforeEnter === 'function') {
          this.beforeEnter();
        }
      },
      onBeforeLeave(): void {
        if (typeof this.beforeLeave === 'function') {
          this.beforeLeave();
        }
      },
      onBeforeAppear(): void {
        if (typeof this.beforeAppear === 'function') {
          this.beforeAppear();
        }
      },
      onEnter(): void {
        if (typeof this.enter === 'function') {
          this.enter();
        }
      },
      onLeave(): void {
        if (typeof this.leave === 'function') {
          this.leave();
        }
      },
      onAppear(): void {
        if (typeof this.appear === 'function') {
          this.appear();
        }
      },
      onAfterEnter(): void {
        if (typeof this.afterEnter === 'function') {
          this.afterEnter();
        }
      },
      onAfterLeave(): void {
        if (typeof this.afterLeave === 'function') {
          this.afterLeave();
        }
      },
      onAfterAppear(): void {
        if (typeof this.afterAppear === 'function') {
          this.afterAppear();
        }
      },
      onEnterCancelled(): void {
        if (typeof this.enterCancelled === 'function') {
          this.enterCancelled();
        }
      },
      onLeaveCancelled(): void {
        if (typeof this.leaveCancelled === 'function') {
          this.leaveCancelled();
        }
      },
      onAppearCancelled(): void {
        if (typeof this.appearCancelled === 'function') {
          this.appearCancelled();
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  $transition-duration: 0.3s;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity $transition-duration ease-out;
    will-change: opacity;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-top-enter-active,
  .slide-top-leave-active {
    transition:
      opacity $transition-duration ease-in-out,
      transform $transition-duration ease-in-out;
    will-change: transform, opacity;
  }
  .slide-top-enter,
  .slide-top-leave-to {
    transform: translateY(-100%);
  }

  .slide-bottom-enter-active,
  .slide-bottom-leave-active {
    transition:
      opacity $transition-duration ease-in-out,
      transform $transition-duration ease-in-out;
    will-change: transform, opacity;
  }
  .slide-bottom-enter,
  .slide-bottom-leave-to {
    transform: translateY(100%);
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition:
      opacity $transition-duration ease-in-out,
      transform $transition-duration ease-in-out;
    will-change: transform, opacity;
  }
  .slide-left-enter,
  .slide-left-leave-to {
    transform: translateX(-100%);
  }

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition:
      opacity $transition-duration ease-in-out,
      transform $transition-duration ease-in-out;
    will-change: transform, opacity;
  }
  .slide-right-enter,
  .slide-right-leave-to {
    transform: translateX(100%);
  }
</style>
